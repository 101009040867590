<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-card-title>
                <span>Create Page</span>
              </v-card-title>
              <v-card-text>
                <v-tabs background-color="deep-purple accent-4" left dark>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab>Details</v-tab>
                  <v-tab>Media</v-tab>

                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <v-form
                          ref="form"
                          v-model="validationRules.valid"
                          lazy-validation
                          id="page_form"
                        >
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-select
                                clearable
                                :items="menusFilter"
                                v-model="editedItem.menu_id"
                                item-text="title"
                                item-value="id"
                                label="Menu"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                v-model="editedItem.name"
                                :counter="200"
                                :rules="validationRules.nameRules"
                                label="Name*"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                v-model="editedItem.title"
                                :counter="200"
                                :rules="validationRules.nameRules"
                                label="Title*"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                v-model="editedItem.slug"
                                :counter="300"
                                :rules="validationRules.slugRules"
                                label="Slug*"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <label>Body*</label>
                              <editor
                                :rules="validationRules.nameRules"
                                ref="et_editor"
                                v-model="editedItem.body"
                                :api-key="tinyKey"
                                :init="editorInit"
                              />
                              <br />
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-select
                                clearable
                                v-model="editedItem.enabled"
                                :items="ques"
                                :rules="validationRules.selectedRules"
                                item-text="title"
                                item-value="value"
                                label="Enabled*"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                clearable
                                v-model="editedItem.meta_title"
                                maxLength="255"
                                :counter="255"
                                label="Meta Title"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-textarea
                                v-model="editedItem.meta_keywords"
                                label="Meta Keywords"
                                maxLength="255"
                                :counter="255"
                                clearable
                              ></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-textarea
                                v-model="editedItem.meta_description"
                                label="Meta Description"
                                maxLength="255"
                                :counter="255"
                                clearable
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <ModuleMedia
                      moduleType="image"
                      moduleOf="page"
                      moduleMultiple="false"
                      :moduleImages="moduleImages"
                      :moduleImagesOld="moduleImagesOld"
                    ></ModuleMedia>
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="clearDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  :disabled="!validationRules.valid"
                  color="blue darken-1"
                  text
                  @click="saveItem(editedItem)"
                >
                  Save
                </v-btn>
              </v-card-actions>
              <v-col cols="12">
                <div
                  v-if="errors != undefined && errors.length >= 0"
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div
                    class="alert-text"
                    v-for="(error, i) in errors"
                    :key="i"
                  >
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import { MENUS_SIMPLE_LIST } from "@/core/services/store/menus.module";
import {
  CREATE_EMAIL_TEMPLATE_PAGE,
  FETCH_EMAIL_TEMPLATE,
  CLEAR_PAGE_ERRORS,
  UPDATE_EMAIL_TEMPLATE,
} from "@/core/services/store/pages.module";
import {
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import { bus } from "@/main";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";

export default {
  name: "email-templates",
  components: {
    ModuleMedia
  },
  data() {
    return {
      editorInit: {
        branding: false,
        height: 500,
        menubar: true,
        images_dataimg_filter: function(img) {
          return img.hasAttribute("internal-blob");
        },
        images_upload_url: "image",
        automatic_uploads: false,
        plugins: [
          "advlist autolink template lists code link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code wordcount fullscreen"
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | code | link | image | fullscreen | template"
      },
      moduleImages: [],
      moduleImagesOld: [],
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 200) ||
            "Must be less than or equal to 200 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        slugRules: [
          v => !!v || "This field is required",
          v =>
            (v && v.length <= 300) ||
            "Must be less than or equal to 300 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        selectedRules: [ v => !!v || "This field is required" ]
      },
      items: [],
      menusFilter: [],
      show: false,
      editedItem: {},
      ques: [
        { value: "0", title: "No" },
        { value: "1", title: "Yes" },
      ],
    };
  },

  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Email Templates Management", route: "email-templates" },
      { title: "Email Templates" },
    ]);
    this.getMenus();
    this.getData();
  },

  methods: {
    clearDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
              .dispatch("delete_uploaded_image", {
                files: mFiles,
                type: "modal",
              })
              .then(() => {
                that.moduleImages = [];
                that.dialog = false;
                bus.$emit("moduleImagesDeletedByModal");
              });
          },
        });
      }
      this.editedItem = {};
    },

    getMenus() {
      this.$store.dispatch(MENUS_SIMPLE_LIST).then((data) => {
        this.menusFilter = data.records.menusList;
      });
    },

    saveItem(item) {
      item.email_template = true;
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_EMAIL_TEMPLATE_PAGE;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item,
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_EMAIL_TEMPLATE;
        }
        this.$store.dispatch(CLEAR_PAGE_ERRORS);
        // save the record
        this.$store.dispatch(method, { id: id, data: data.data });
      }
    },

    getData() {
      this.$store.dispatch(FETCH_EMAIL_TEMPLATE).then((data) => {
        this.editedItem = data.records
      });
    }
  },
};
</script>
